// IMPORTANT: This container is intended to be used by every single route in the app and
// the container is likely to be created prior to any route rendering or other app initialization.
// Therefore we need to be _very_ mindful about what services are included here and
// how they are instantiated.

import {
  createServiceDefinitionHelpers,
  type ExtractServiceMap,
  ServiceContainer,
} from '@lib/service-container/container';

import { getAppConfig } from '@/services/app-config/service';

import { createDefaultLoggingServiceInstance } from './default-logging-service-instance';

/**
 * IMPORTANT: This type is primarily intended for use in unit test and/or storybook mocks.
 * You _can_ use this type elsewhere, but it's recommended to use the `CriticalServiceContainer` type
 * in most app code.
 */
export type CriticalServiceContainerInterface = ClassInterface<CriticalServiceContainer>;

export type CriticalServiceContainer = Awaited<ReturnType<typeof createCriticalServicesContainer>>;

export type CriticalServiceMap = ExtractServiceMap<
  Awaited<ReturnType<typeof createCriticalServicesContainer>>
>;

export async function createCriticalServicesContainer() {
  const appConfig = await getAppConfig();

  const loggingServiceInstance = createDefaultLoggingServiceInstance({ appConfig });

  const { createInstanceServiceDefinition } = createServiceDefinitionHelpers();

  const appConfigServiceDefinition = createInstanceServiceDefinition({
    name: 'appConfig',
    instance: appConfig,
  });

  const loggingServiceDefinition = createInstanceServiceDefinition({
    name: 'logging',
    instance: loggingServiceInstance,
  });

  const criticalServicesContainer = new ServiceContainer({
    serviceDefinitions: [appConfigServiceDefinition, loggingServiceDefinition],
  });

  return criticalServicesContainer;
}
