import type { BrazeServiceConfig } from '@/services/braze/service';
import type { PaysafeServiceConfig } from '@/services/paysafe/service';
import type { PciProxyServiceConfig } from '@/services/pci-proxy/service';
import type { SiftServiceConfig } from '@/services/sift/service';

import type { AmpliServiceConfig } from '../ampli/service';
import type { GeoComplyServiceConfig } from '../geocomply/service';
import type { GoogleTagManagerServiceConfig } from '../google-tag-manager/service';
import type { IntercomServiceConfig } from '../intercom/service';
import type { LoggingServiceConfig } from '../logging/service';

// review: we should consider adding this type to the global `declarations.d.ts` file
export type AppEnv = 'production' | 'certification' | 'staging' | 'development';

export type AppConfig = {
  environment: AppEnv;
  /**
   * WARNING: this value is typically only available at build time via CI pipeline.
   * Be aware that this value will likely be `undefined` in local development, so
   * reference it with caution.
   */
  clientVersion: string | undefined;
  services: {
    ampli: Pick<AmpliServiceConfig, 'environment'>;
    braze: BrazeServiceConfig;
    geoComply: GeoComplyServiceConfig;
    googleTagManager: GoogleTagManagerServiceConfig;
    intercom: IntercomServiceConfig;
    logging: LoggingServiceConfig;
    paysafe: PaysafeServiceConfig;
    pciProxy: PciProxyServiceConfig;
    sift: SiftServiceConfig;
  };
};

/**
 * IMPORTANT: be sure to reference environment variables via `process.env.XXXXXX`
 * instead of destructuring or indexed access. In other words, code should be:
 *
 * const myVar = process.env.MY_VAR;
 *
 * but not:
 * const [MY_VAR] = process.env;
 * or
 * const myVar = process.env['MY_VAR'];
 *
 * This ensures that the webpack DefinePlugin will properly replace env var
 * references with their actual values at build time.
 */

export async function getAppConfig() {
  const appEnv = getAppEnv();

  // reminder: this value is typically only available at build time via CI pipeline
  const clientVersion = process.env.CLIENT_VERSION;

  const config: AppConfig = {
    environment: appEnv,
    clientVersion,
    services: {
      ampli: {
        environment: appEnv === 'production' ? 'production' : 'development',
      },
      braze: getBrazeConfig(),
      geoComply: getGeoComplyConfig(),
      googleTagManager: getGoogleTagManagerConfig(),
      intercom: getIntercomConfig(),
      logging: getLoggingServiceConfig({ clientVersion }),
      paysafe: getPaysafeConfig(),
      pciProxy: getPciProxyConfig(),
      sift: getSiftConfig(),
    },
  };

  return config;
}

function getAppEnv() {
  const appEnv = process.env.APP_ENV as AppEnv;

  if (!appEnv) {
    throw new Error('APP_ENV is not set');
  }

  if (
    appEnv !== 'production' &&
    appEnv !== 'staging' &&
    appEnv !== 'development' &&
    appEnv !== 'certification'
  ) {
    throw new Error('APP_ENV is an unexpected value');
  }

  return appEnv;
}

function getBrazeConfig() {
  const apiKey = process.env.BRAZE_API_KEY;
  validateEnv(apiKey, 'BRAZE_API_KEY');

  const baseUrl = process.env.BRAZE_SDK_ENDPOINT;
  validateEnv(baseUrl, 'BRAZE_SDK_ENDPOINT');

  const safariWebsitePushId = process.env.BRAZE_SAFARI_DOMAIN;
  validateEnv(safariWebsitePushId, 'BRAZE_SAFARI_DOMAIN');

  const config: BrazeServiceConfig = {
    apiKey,
    baseUrl,
    safariWebsitePushId,
  };

  return config;
}

function getGeoComplyConfig() {
  const scriptUrl = process.env.GEO_COMPLY_LINK;
  validateEnv(scriptUrl, 'GEO_COMPLY_LINK');

  const config: GeoComplyServiceConfig = {
    scriptUrl,
  };

  return config;
}

function getGoogleTagManagerConfig() {
  const accountId = process.env.GOOGLE_TAG_MANAGER_ID;
  validateEnv(accountId, 'GOOGLE_TAG_MANAGER_ID');

  const config: GoogleTagManagerServiceConfig = {
    accountId,
  };

  return config;
}

function getIntercomConfig() {
  const appId = process.env.INTERCOM_APP_ID;
  validateEnv(appId, 'INTERCOM_APP_ID');

  const config: IntercomServiceConfig = {
    appId,
  };

  return config;
}

function getLoggingServiceConfig({ clientVersion }: { clientVersion: string | undefined }) {
  const rumApplicationId = process.env.DATADOG_RUM_APPLICATION_ID;
  validateEnv(rumApplicationId, 'DATADOG_RUM_APPLICATION_ID');

  const rumClientToken = process.env.DATADOG_RUM_CLIENT_TOKEN;
  validateEnv(rumClientToken, 'DATADOG_RUM_CLIENT_TOKEN');

  const rumEnvironment = process.env.DATADOG_RUM_ENVIRONMENT;
  validateEnv(rumEnvironment, 'DATADOG_RUM_ENVIRONMENT');

  const rumService = process.env.DATADOG_RUM_SERVICE;
  validateEnv(rumService, 'DATADOG_RUM_SERVICE');

  const rumSite = process.env.DATADOG_RUM_SITE;
  validateEnv(rumSite, 'DATADOG_RUM_SITE');

  const logClientToken = process.env.DATADOG_BROWSER_LOG_CLIENT_TOKEN;
  validateEnv(logClientToken, 'DATADOG_BROWSER_LOG_CLIENT_TOKEN');

  const logClientEnvironment = process.env.DATADOG_BROWSER_LOG_ENVIRONMENT;
  validateEnv(logClientEnvironment, 'DATADOG_BROWSER_LOG_ENVIRONMENT');

  const logService = process.env.DATADOG_BROWSER_LOG_SERVICE;
  validateEnv(logService, 'DATADOG_BROWSER_LOG_SERVICE');

  const logSite = process.env.DATADOG_BROWSER_LOG_SITE;
  validateEnv(logSite, 'DATADOG_BROWSER_LOG_SITE');

  const config: LoggingServiceConfig = {
    rum: {
      applicationId: rumApplicationId,
      clientToken: rumClientToken,
      env: rumEnvironment,
      service: rumService,
      site: rumSite,
      version: clientVersion ?? 'development',
    },
    browserLog: {
      clientToken: logClientToken,
      env: logClientEnvironment,
      service: logService,
      site: logSite,
      version: clientVersion ?? 'development',
    },
  };

  return config;
}

function getPaysafeConfig() {
  const apiKey = process.env.PAYSAFE_API_KEY;
  validateEnv(apiKey, 'PAYSAFE_API_KEY');

  const scriptUrl = process.env.PAYSAFE_SCRIPT_URL;
  validateEnv(scriptUrl, 'PAYSAFE_SCRIPT_URL');

  // review: we probably don't need to convert these values to numbers
  // The Paysafe SDK defines their types as `number`, but we created those types
  // and it's likely the Paysafe SDK doesn't validate them as numbers.
  // Not anything we need to spend a lot of time on, but something to consider.
  const fantasyPaysafeMerchantId = process.env.FANTASY_PAYSAFE_MERCHANT;
  validateEnv(fantasyPaysafeMerchantId, 'FANTASY_PAYSAFE_MERCHANT');
  const resolvedFantasyPaysafeMerchantId = Number(fantasyPaysafeMerchantId);
  if (Number.isNaN(resolvedFantasyPaysafeMerchantId)) {
    throw new Error('FANTASY_PAYSAFE_MERCHANT must be a number');
  }

  const fantasyWorldpayMerchantId = process.env.FANTASY_WORLDPAY_MERCHANT;
  validateEnv(fantasyWorldpayMerchantId, 'FANTASY_WORLDPAY_MERCHANT');
  const resolvedFantasyWorldpayMerchantId = Number(fantasyWorldpayMerchantId);
  if (Number.isNaN(resolvedFantasyWorldpayMerchantId)) {
    throw new Error('FANTASY_WORLDPAY_MERCHANT must be a number');
  }

  const sportsbookPaysafeMerchantId = process.env.SPORTSBOOK_PAYSAFE_MERCHANT;
  validateEnv(sportsbookPaysafeMerchantId, 'SPORTSBOOK_PAYSAFE_MERCHANT');
  const resolvedSportsbookPaysafeMerchantId = Number(sportsbookPaysafeMerchantId);
  if (Number.isNaN(resolvedSportsbookPaysafeMerchantId)) {
    throw new Error('SPORTSBOOK_PAYSAFE_MERCHANT must be a number');
  }

  const environment = process.env.PAYSAFE_ENVIRONMENT;
  validateEnv(environment, 'PAYSAFE_ENVIRONMENT');

  const config: PaysafeServiceConfig = {
    apiKey,
    scriptUrl,
    fantasyPaysafeMerchantId: resolvedFantasyPaysafeMerchantId,
    fantasyWorldpayMerchantId: resolvedFantasyWorldpayMerchantId,
    sportsbookPaysafeMerchantId: resolvedSportsbookPaysafeMerchantId,
    environment,
    currencyCode: 'USD',
  };

  return config;
}

function getPciProxyConfig() {
  const merchantId = process.env.SECURE_FIELDS_INIT_KEY;
  validateEnv(merchantId, 'SECURE_FIELDS_INIT_KEY');

  const scriptUrl = process.env.PCI_PROXY_URL;
  validateEnv(scriptUrl, 'PCI_PROXY_URL');

  const config: PciProxyServiceConfig = {
    merchantId,
    scriptUrl,
  };

  return config;
}

function getSiftConfig() {
  const scriptUrl = process.env.SIFT_SCRIPT_URL;
  validateEnv(scriptUrl, 'SIFT_SCRIPT_URL');

  const accountId = process.env.SIFT_ACCOUNT_ID;
  validateEnv(accountId, 'SIFT_ACCOUNT_ID');

  const config: SiftServiceConfig = {
    scriptUrl,
    accountId,
  };

  return config;
}

function validateEnv(condition: unknown, envVarName: string): asserts condition {
  if (condition) {
    return;
  }

  throw new Error(`${envVarName} is not set`);
}
